button.ant-btn.deep-sky-blue-and-white {
    font-family: SFProDisplay-Semibold;
    color: var(--white);
    font-size: 19px;
    border-radius: 5px;
    background-color: var(--deep-sky-blue);
    border-color: var(--deep-sky-blue);
    box-shadow: 0 1px 4px 0 rgba(85, 92, 97, 0.34);
}
button.ant-btn.deep-sky-blue-and-white-mobile {
    font-family: SFProDisplay-Semibold;
    color: var(--white);
    font-size: 14px;
    border-radius: 5px;
    background-color: var(--deep-sky-blue);
    border-color: var(--deep-sky-blue);
    box-shadow: 0 1px 4px 0 rgba(85, 92, 97, 0.34);
}

button.ant-btn.deep-sky-blue-and-white-menu {
    font-family: SFProDisplay-Semibold;
    color: var(--white);
    font-size: 20px;
    border-radius: 5px;
    background-color: var(--deep-sky-blue);
    border-color: var(--deep-sky-blue);
    box-shadow: 0 1px 4px 0 rgba(85, 92, 97, 0.34);
}

button.ant-btn.white-and-deep-sky-blue {
    font-family: SFProDisplay-Semibold;
    color: var(--deep-sky-blue);
    font-size: 19px;
    border-radius: 5px;
    background-color: var(--white);
    border-color: var(--white);
    box-shadow: 0 1px 4px 0 rgba(85, 92, 97, 0.34);
}
button.ant-btn.white-and-deep-sky-blue-mobile {
    font-family: SFProDisplay-Semibold;
    color: var(--deep-sky-blue);
    font-size: 14px;
    border-radius: 5px;
    background-color: var(--white);
    border-color: var(--white);
    box-shadow: 0 1px 4px 0 rgba(85, 92, 97, 0.34);
}
button.ant-btn.white-and-deep-sky-blue-menu {
    font-family: SFProDisplay-Semibold;
    color: var(--deep-sky-blue);
    font-size: 20px;
    border-radius: 5px;
    background-color: var(--white);
    border-color: var(--white);
    box-shadow: 0 1px 4px 0 rgba(85, 92, 97, 0.34);
}

button.ant-btn.button-create-account {
    width: 100%;
    height: 50px;
    font-family: SFProDisplay-Semibold;
    font-size: 16px;
    border-radius: 5px;
    color: var(--deep-sky-blue);
    background-color: var(--white);
    border: solid 1px var(--deep-sky-blue);
}
button.ant-btn.button-create-account:hover {
    color: var(--white);
    background-color: var(--deep-sky-blue);
    border: solid 1px var(--deep-sky-blue);
}

button.ant-btn.button-try-free {
    width: 100%;
    height: 50px;
    font-family: SFProDisplay-Semibold;
    font-size: 16px;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--deep-sky-blue);
    border: solid 1px var(--deep-sky-blue);
}
button.ant-btn.button-try-free:hover {
    color: var(--white);
    background-color: var(--dodger-blue);
    border: solid 1px var(--deep-sky-blue);
}
