@import '../node_modules/antd/dist/antd.css';
@import 'css/colors.css';
@import 'css/buttons.css';

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.top-bar-link {
    font-family: SFProDisplay-Medium;
    font-size: 16px;
    color: var(--charcoal-grey);
    margin-left: 10px;
    margin-right: 10px;
}

.top-bar-link:hover {
    color: var(--deep-sky-blue);
}
.top-bar-link .anticon {
    margin-left: 4px;
}
.top-bar-link .anticon > svg {
    width: 12px;
    fill: var(--deep-sky-blue);
}

.top-bar-link-doc {
    font-family: SFProDisplay-Medium;
    font-size: 16px;
    color: var(--white);
    margin-left: 10px;
    margin-right: 10px;
}

.top-bar-link-doc:hover {
    color: var(--black);
}

.bottom-menu {
    font-family: SFProDisplay-Semibold;
    font-size: 14px;
    color: var(--battleship-grey);
}
.bottom-menu:hover {
    color: var(--deep-sky-blue);
}

.footer-link .ant-typography:hover {
    color: var(--deep-sky-blue) !important;
}

.follow > img {
    filter: grayscale(1);
}

.follow > img:hover {
    filter: none;
}

.select-language > div {
    background-color: #f7f8fa;
    padding: 4px 0 4px 12px;
    width: 160px;
    color: var(--battleship-grey);
    font-family: SFProDisplay-Semibold;
    font-size: 14px;
}

.top-bar-menu-item {
    padding: 12px;
    border-radius: 4px;
    background-color: var(--white);
}

.top-bar-menu-item:hover {
    background-color: #f0f8ff;
}

.ant-dropdown-menu-item > a {
    background-color: var(--white);
}

.banner-image {
    box-shadow: 0 0 30px 10px rgba(184, 184, 184, 0.88);
    border-radius: 20px 0 0 20px;
}

.banner-image-mobile {
    box-shadow: 0 0 30px 10px rgba(184, 184, 184, 0.88);
    border-radius: 10px;
}

.feature-item {
    min-height: 164px;
    padding: 28px 20px 32px 20px;
    border-radius: 5px;
    /*box-shadow: 4px 6px 18px 2px var(--pale-grey-two);*/
    box-shadow: 0 6px 18px 0 rgba(163, 175, 184, 0.28);
    background-color: var(--white);
}

.feature-item-shadow {
    box-shadow: 4px 4px 40px -20px rgba(184, 184, 184, 0.88);
    border-radius: 5px;
}

.feature-item-mobile {
    margin: 16px;
    margin-bottom: 32px;
    height: 164px;
    padding: 28px 20px 32px 20px;
    border-radius: 5px;
    /*box-shadow: 4px 6px 18px 2px var(--pale-grey-two);*/
    box-shadow: 0 6px 18px 0 rgba(163, 175, 184, 0.28);
    background-color: var(--white);
}
.feature-item-mobile-fake {
    margin: 0px;
    height: 164px;
    padding: 0px 0px 0px 1px;
    border-radius: 5px;
    /*box-shadow: 4px 6px 18px 2px var(--pale-grey-two);*/
    box-shadow: 0 6px 18px 0 rgba(163, 175, 184, 0.28);
    background-color: var(--white);
}
