.feature .banner {
    width: 100%;
    height: 100%;
    transform: rotate(-180deg);
    border-radius: 500px;
    background-image: linear-gradient(to bottom, #e8fff4, #f9fffc);
}

.feature .Rectangle {
    width: 1271px;
    height: 100px;
    transform: rotate(-360deg);
    background-image: linear-gradient(to left, #fafdff, #eaf5ff);
}

.feature .built-with-dev {
    width: 1664px;
    height: 1141px;
    background-image: linear-gradient(to top, #eaf5ff, #ffffff);
}

.founders img {
    border-radius: 3px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    object-fit: cover;
}

.founders h2 {
    text-align: left;
    margin-left: 14px;
    margin-top: 10px;
}

.founders section {
    display: flex;
}

.founders section a {
    margin-top: 10px;
    margin-left: 10px;
}

.founders .founder {
    text-align: left;
}
