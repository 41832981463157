.faq .description {
    font-family: SFProDisplay-Regular;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: var(--cool-grey-three);
    margin-bottom: 120px;
}

.faq .ant-collapse-item {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    background-color: var(--white);
}

.faq .ant-collapse-item > .ant-collapse-header {
    padding-top: 24px;
    padding-bottom: 24px;
}

.pricing .ant-table-tbody > tr > td:not(:first-child) {
    text-align: center;
    width: 230px;
}

.pricing .ant-table-tbody > tr > td {
    border-color: var(--pale-grey);
}
.pricing .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
    background: unset;
}

.pricing .ant-table-small {
    border-radius: 8px;
    border-color: var(--pale-grey);
    padding-top: 8px;
    padding-bottom: 8px;
}
.pricing .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
    padding-top: 0px;
    padding-bottom: 0px;
}

.pricing .extention .ant-table-small {
    border: none;
}

.pricing-plan {
    margin-top: 16px;
    border-radius: 10px;
    border: solid 1px var(--cloudy-blue);
    background-color: var(--white);
}
.pricing-plan:hover {
    margin-top: 16px;
    border-radius: 10px;
    border: solid 1px var(--deep-sky-blue);
    background-color: var(--white);
}
.pricing-plan-selected {
    margin-top: 16px;
    border-radius: 10px;
    border: solid 1px var(--deep-sky-blue);
    background-color: var(--white);
    box-shadow: 0 6px 18px 0 rgba(163, 175, 184, 0.28);
}
.estimate-cost {
    border-radius: 10px;
    border: solid 1px var(--cloudy-blue);
    background-color: var(--white);
}

.estimate-cost-mtr {
    padding-left: 64px;
    padding-top: 48px;
    padding-right: 86px;
}
.estimate-cost-start {
    border-left: solid 1px var(--cloudy-blue);
    padding: 40px;
}

.compare-column-item-first {
    height: 80px;
    border: solid 1px var(--pale-grey-two);
    border-bottom: none;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.compare-column-item-last {
    height: 80px;
    border: solid 1px var(--pale-grey-two);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.compare-plan-mobile .compare-column-item-last {
    height: 80px;
    border: solid 1px var(--pale-grey-two);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top: none;
}
.compare-column-item {
    height: 80px;
    border: solid 1px var(--pale-grey-two);
    border-bottom: none;
}
.compare-plan-mobile .compare-column-item {
    height: 80px;
    border: solid 1px var(--pale-grey-two);
    border-bottom: none;
    border-top: none;
}
.compare-column-footer-title {
    height: 50px;
}
.compare-column-title {
    height: 80px;
}

.plan-tag {
    position: absolute;
    width: 80px;
    padding: 4px 0 4px 0;
    border-radius: 34px;
    border: solid 1px var(--algae-green);
    background: var(--white);
    top: 0;
}

.medium-slate-grey {
    font-family: SFProDisplay-Medium;
    font-size: 16px;
    color: var(--slate-grey);
}

.radio-plan-selection {
    width: 268px;
    height: 55px;
    border-radius: 34px;
    border: solid 1px var(--pale-grey);
    background-color: var(--white);
}

.ant-slider-rail {
    height: 9px;
    background-color: var(--light-sky-blue-two);
    border-radius: 4px;
}
.ant-slider:hover .ant-slider-rail {
    background-color: var(--light-sky-blue-two);
}
.ant-slider:hover .ant-slider-track {
    background-color: var(--deep-sky-blue);
}
.ant-slider-track {
    height: 9px;
    background-color: var(--deep-sky-blue);
}

.ant-slider-dot {
    position: absolute;
    top: 0;
    width: 2px;
    height: 9px;
    margin-left: -4px;
    background-color: #fff;
    border: none;
    border-radius: 0;
    cursor: pointer;
}
.ant-slider-dot:last-child {
    width: 0;
}
.ant-slider-handle {
    position: absolute;
    width: 32px;
    height: 32px;
    top: -7px;
    background-color: var(--deep-sky-blue);
    border: solid 6px var(--cloudy-blue);
    margin-top: 0;
    border-radius: 50%;
    transform: none;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border: solid 6px var(--cloudy-blue);
}
.ant-slider-handle:focus {
    transform: none;
    border: solid 6px var(--cloudy-blue);
}

.ant-slider-mark {
    top: -36px;
}
.ant-slider-mark-text {
    font-family: SFProDisplay-Regular;
    font-size: 16px;
    color: var(--cool-grey-two);
}
.ant-slider-mark-text-active {
    font-family: SFProDisplay-Medium;
    font-size: 16px;
    color: var(--slate-grey);
}
