.background-soar-high {
    position: relative;
    background-image: linear-gradient(to bottom, var(--ice-blue) -29%, #eaf5ff 123%);
    height: 1062px;
}

.feature-inactive {
    padding: 16px;
    width: 330px;
    margin: 20px;
    background-color: var(--white);
    border: 2px solid var(--dodger-blue);
    border-radius: 40px;
    font-family: Eina-Semibold;
    font-size: 20px;
    color: var(--dodger-blue);
    text-align: center;
}

.feature-active {
    text-align: center;
    width: 330px;
    height: 138px;
    background-color: var(--white);
    border-radius: 30px;
    font-family: Eina-Semibold;
    font-size: 16px;
    color: var(--dodger-blue);
    box-shadow: 0px 0px 20px 1px rgba(184, 184, 184, 0.88);
}

.platform-button {
    -webkit-transition: background-color 2s; /* For Safari 3.1 to 6.0 */
    transition: background-color 2s;
    height: 104px;
    border-radius: 8px;
    border: solid 1px var(--black);
    background-color: var(--white);
}

.platform-button-selected {
    -webkit-transition: background-color 2s; /* For Safari 3.1 to 6.0 */
    transition: background-color 2s;
    height: 104px;
    border-radius: 8px;
    background-color: var(--black);
}
.platform-button-mobile {
    -webkit-transition: background-color 2s; /* For Safari 3.1 to 6.0 */
    transition: background-color 2s;
    height: 60px;
    border-radius: 8px;
    border: solid 1px var(--black);
    background-color: var(--white);
}

.platform-button-selected-mobile {
    -webkit-transition: background-color 2s; /* For Safari 3.1 to 6.0 */
    transition: background-color 2s;
    height: 60px;
    border-radius: 8px;
    background-color: var(--black);
}

.platform-button-selected-indicator {
    height: 5px;
    border-radius: 100px;
    background-color: #1758f3;
    margin-top: -5px;

    -webkit-transition: width 0.1s ease-in-out;
    -moz-transition: width 0.1s ease-in-out;
    -o-transition: width 0.1s ease-in-out;
    transition: width 0.1s ease-in-out;
}

.trusted-customer-border {
    position: absolute;
    right: 0;
    margin-left: 20px;
    margin-top: 20px;
    border: solid 3px var(--algae-green-50);
    padding: 60px 50px 60px 50px;
}

.trusted-customer-container {
    right: 0;
    position: absolute;
    text-align: left;
    padding: 60px 50px 60px 50px;
    margin-right: 20px;
    background-color: var(--off-white);
}

.trusted-customer {
    position: relative;
    height: 520px;
    padding-right: 2px;
}

.quote {
    text-align: left;
}
.quote-image {
    position: absolute;
}
.quote-text {
}

.ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    margin: -60px 2px;
    padding: 0;
    text-align: center;
    vertical-align: top;
    border-radius: 3px;
    background: var(--white-three);
}

.trusted-customer .ant-carousel .slick-dots li button {
    width: 36px;
    height: 6px;
    border-radius: 3px;
    background: var(--white-three);
}

.trusted-customer .ant-carousel .slick-dots li.slick-active button {
    background: var(--deep-sky-blue);
}

.trusted-customer .ant-carousel .slick-dots {
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    padding: 0;
    text-align: left;
    list-style: none;
    left: 0;
}

.trusted-customer .powered-apps {
    border: 1px solid #ebebeb;
    border-left: none;
    border-right: none;
    padding: 8px 16px 8px 24px;
}

.trusted-customer-mobile .ant-carousel {
    width: 100%;
}
.trusted-customer-mobile .ant-carousel .slick-dots li button {
    width: 24px;
    height: 4px;
    border-radius: 3px;
    background: var(--white-three);
}
.trusted-customer-mobile .ant-carousel .slick-dots {
    margin: -40px 2px;
}

.trusted-customer-mobile .ant-carousel .slick-dots li.slick-active button {
    background: var(--deep-sky-blue);
}

.powered-apps-mobile {
    border-bottom: 1px solid #ebebeb;
    padding: 0 26px 40px 24px;
}

.button-connect-app:hover {
    cursor: pointer;
}
