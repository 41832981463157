.card-container p {
    margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
}

.ant-tabs-bar {
    visibility: hidden;
}
#components-tabs-demo-card-top .code-box-demo {
    background: #f5f5f5;
    overflow: hidden;
    padding: 24px;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #141414;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    border-color: #141414;
    background: #141414;
}

.tab-button {
    background: var(--white);
    margin: auto;
    padding: 8px;
    flex-grow: 1;
    cursor: pointer;
}

.tab-button-selected {
    background: var(--slate-grey);
    margin: auto;
    padding: 8px;
    flex-grow: 1;
    cursor: pointer;
}

.card-container {
    font-family: monospace;
}
