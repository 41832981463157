.button-top:hover {
    cursor: pointer;
}

.ant-popover.ant-popover-placement-bottomRight {
    left: 0px !important;
    right: 0px !important;
    top: 60px !important;
}

.ant-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
    width: 100% !important;
}
.ant-menu-root.ant-menu-inline {
    border-right: none;
}

.ant-menu-submenu-title {
    padding-left: 14px !important;
}

.ant-popover .ant-divider-horizontal {
    margin: 0;
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
    opacity: 0;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
    font-size: 22px;
    font-family: SFProDisplay-Regular;
}
