.ant-form-extra {
    font-family: SFProDisplay-Semibold;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--cool-grey);
}

.ant-form-item-label > label {
    font-family: SFProDisplay-Medium;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: var(--charcoal-grey);
}

.ant-form-item.no-bottom-margin {
    margin-bottom: 20px;
    border-color: #101010;
}

.ant-input {
    font-family: SFProDisplay-Regular;
    font-size: 18px;
    border-radius: 4px;
    border: solid 1px var(--light-blue-grey);
    background-color: var(--white);
    height: 56px;
}

.ant-input::placeholder {
    font-family: SFProDisplay-Regular;
    font-size: 18px;
    color: var(--cool-grey-three);
}
