.contact-us .form-container {
    width: 580px;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(50, 58, 62, 0.15);
    border: solid 1px #ebebeb;
    background-color: var(--white);
    padding: 70px;
}

.email-us {
    border-radius: 10px;
    border: solid 1.5px var(--cloudy-blue);
    background-color: var(--white);
    text-align: center;
    padding: 68px 32px 68px 32px;
}
