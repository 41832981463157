.terms-left-menu {
    /* position: absolute; */
}

div.terms-policy {
    /* padding: 32px 52px 0px 44px; */
    font-family: SFProDisplay-Regular;
}
.terms-policy .ant-menu-item {
    text-align: left;
    /* height: 58px; */
    /* border:  solid 1px var(--pale-grey-two); */
    /* background-color: var(--white); */
    margin-top: 0px;
    margin-bottom: 0px !important;
    font-family: SFProDisplay-Semibold;
    font-size: 16px;
    line-height: 28px;
    color: var(--slate-grey);
    padding-top: 8px;
    padding-left: 0 !important;
}
.terms-policy .ant-menu-item:hover {
    /* height: 58px; */
    /* border:  solid 1px var(--pale-grey-two); */
    /* background-color: var(--pale-grey); */
    margin-top: 0px;
    margin-bottom: 0px !important;
    font-family: SFProDisplay-Semibold;
    font-size: 16px;
    line-height: 28px;
    color: #2f9cf5;
    padding-top: 8px;
}
.terms-policy .ant-menu-item-selected::after {
    border-right: none;
}
.terms-policy .ant-menu-item:not(:first-child) {
    border-top: none;
}
.terms-policy .ant-menu-item:first-child {
    border-radius: 4px 4px 0 0;
}
.terms-policy .ant-menu-item:last-child {
    border-radius: 0 0 4px 4px;
}
.terms-policy .ant-menu-item-selected {
    color: #2f9cf5;
    background: #ffffff !important;
}
