.real-time-state-background {
    position: absolute;
    width: 100%;
    height: 421px;
    top: 260px;
    opacity: 0.2;
    border-radius: 28.6px;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to bottom, #afafaf, var(--white));
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, var(--pale-grey-two), var(--pale-grey-two)),
        linear-gradient(to bottom, #afafaf, var(--white));
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.real-time-state-background-mobile {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 700px;
    top: 230px;
    opacity: 0.2;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to bottom, #afafaf, var(--white));
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, var(--pale-grey-two), var(--pale-grey-two)),
        linear-gradient(to bottom, #afafaf, var(--white));
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.logo-shadow {
    padding: 32px 64px 32px 64px;
    background-color: var(--white);
    box-shadow: 10px 10px 40px 0px rgba(184, 184, 184, 0.88);
    border-radius: 10px;
}
