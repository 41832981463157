.documentation-top {
    background-image: linear-gradient(106deg, var(--deep-sky-blue) 0%, #1998ff 100%);
    height: 480px;
    width: 100%;
    position: absolute;
}
.welcome-content {
    border-radius: 6px;
    border: solid 0.8px var(--pale-grey-two);
    background-color: var(--white);
    text-align: left;
    padding-top: 60px;
    padding-bottom: 60px;
}

.semi-bold-20-battleship-grey {
    font-family: SFProDisplay-Semibold;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--battleship-grey);
}

.semi-bold-20-black {
    font-family: SFProDisplay-Semibold;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--black);
}

.regular-20-charcoal-grey {
    font-family: SFProDisplay-Regular;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--charcoal-grey);
}

.semi-bold-16-black {
    font-family: SFProDisplay-Semibold;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black);
}

.medium-16-deep-sky-blue {
    font-family: SFProDisplay-Medium;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--deep-sky-blue);
}

.regular-16-charcoal-grey {
    font-family: SFProDisplay-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--charcoal-grey);
}

ul > li {
    padding-left: 10px;
}
ol > li {
    padding-left: 10px;
}
